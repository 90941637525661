import React from "react"
import Layout from '../../components/layout'; 
import DropdownMenu from '../../components/dropdownMenu'
import productStyles from '../../styles/product.module.scss'
import image from '../../images/vector-image-3.jpg';
import dropdownStyles from '../../styles/dropdown.module.scss'

const ReferenciasPage = () => {
    return(
        <Layout>
            <DropdownMenu/>
            <div className={productStyles.descripcion}>
                <h3>Referencias Laborales</h3>
                <p className={productStyles.texto}>Verificación del candidato: tracto laboral de empleadores anteriores a través de entrevistas telefónicas.</p>
                <img className={dropdownStyles.image} src={image} alt="imagen productos" /> 
            </div>
            
        </Layout>
    ) 
}
export default ReferenciasPage;